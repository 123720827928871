<template>
  <view-wrapper title="采购订单">
    <template #header>
      <a-tabs type="card" v-model:activeKey="tabs.purchaseStatus" @change="tabs.change" class="tabs-card-self">
        <a-tab-pane
          :key="item.code"
          :tab="item.message"
          v-for="item in statusData"
          :disabled="table.loading"
        ></a-tab-pane>
      </a-tabs>
    </template>
    <div style="background: #fff; padding: 24px" class="p-[24px] bg-[#fff]">
      <a-spin :spinning="table.loading">
        <form-query :model="form.data" @search="form.search">
          <a-row>
            <a-col :span="7">
              <a-form-item has-feedback label="VIN码">
                <a-input v-model:value="form.data.vin" placeholder="请输入" />
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item has-feedback label="配件信息：">
                <a-input v-model:value="form.data.inquiryGoodsName" placeholder="请输入" />
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item has-feedback label="商家名称">
                <a-input v-model:value="form.data.quickSearch" placeholder="请输入" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <a-form-item has-feedback label="订单编号">
                <a-input v-model:value="form.data.purchaseSerialNo" placeholder="请输入" />
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item has-feedback label="下单日期">
                <e-datepicker
                  type="range"
                  v-model:start="form.data.purchaseStartDate"
                  v-model:end="form.data.purchaseEndDate"
                ></e-datepicker>
              </a-form-item>
            </a-col>
            <a-col :span="3" :offset="7" class="flex justify-end">
              <a-button @click="form.init">重置</a-button>
              <!-- <a-button style="margin-left: 10px" type="primary" @click="form.search">查询</a-button> -->
            </a-col>
          </a-row>
        </form-query>
        <ul>
          <li v-for="(inquiry, index) of table.data" :key="inquiry.id">
            <div class="py-[16px] flex justify-between">
              <div>
                <template v-if="inquiry.vin">
                  <span class="font-bold">VIN码</span>
                  <span class="min-w-[120px] mxn-w-[200px] ml-[16px] truncate"> {{ inquiry.vin }}</span>
                  <span class="min-w-[120px] mxn-w-[200px] ml-[16px] truncate"
                    >{{
                      `${inquiry.vehicleBrand} ${inquiry.vehicleSubBrand} ${inquiry.vehicleSystem} ${inquiry.vehicleYear} ${inquiry.vehicleDisplacement}`
                    }}
                  </span>
                  <span class="min-w-[120px] mxn-w-[200px] ml-[16px] truncate">{{ inquiry.vehiclePlateNo || '' }}</span>
                </template>
                <span class="font-bold" v-else>易损件</span>
              </div>
              <div>
                <span class="font-bold">询价单号</span>
                <span class="min-w-[120px] mxn-w-[200px] ml-[16px] truncate">{{ inquiry.serialNo }}</span>
              </div>
            </div>
            <div class="flex list-item-content">
              <div :class="['item-divider', { 'item-divider-color': table.row.className(index) }]"></div>
              <template v-if="!!inquiry.purchases.length">
                <section style="flex: 1" class="overflow-hidden">
                  <div v-for="(purchase, indexTwo) in inquiry.showPurchases" :key="purchase.id">
                    <e-collapse-item :is-expand="indexTwo === 0">
                      <template #title>
                        <div class="flex justify-between operation-box">
                          <ul class="flex items-center">
                            <li class="w-[250px]">
                              <span class="font-bold" style="margin-right: 8px">订单号</span>
                              <span>{{ purchase.serialNo }}</span>
                            </li>
                            <li class="w-[100px] ml-[24px]">
                              <span class="font-bold text-[16px]">
                                {{ formatMoney(purchase.amount, '￥') }}
                              </span>
                            </li>
                          </ul>
                          <ul class="flex items-center">
                            <li class="max-w-[100px] min-w-[80px] font-bold">
                              <a-tooltip :title="purchase.supplier.name || ''">
                                <span>{{ purchase.supplier.shortName }}</span>
                              </a-tooltip>
                            </li>
                            <li class="max-w-[100px] min-w-[80px] flex items-center">
                              <template v-if="purchase.supplierChatUser">
                                <a-tooltip :title="purchase.supplierChatUser.name">
                                  <span class="font-bold truncate text-right" style="width: 50px">{{
                                    purchase.supplierChatUser.name
                                  }}</span>
                                </a-tooltip>
                                <a-tooltip :title="purchase.supplierChatUser.cellphone">
                                  <PhoneFilled class="cursor-pointer mx-[10px]" style="color: #0d8d8d" />
                                </a-tooltip>
                                <MessageFilled
                                  @click="webim.open(purchase.supplierChatUser)"
                                  class="cursor-pointer"
                                  style="color: #ffd100"
                                />
                              </template>
                            </li>
                          </ul>
                          <ul class="flex items-center">
                            <li class="w-[150px] mr-[50px]">
                              <span class="font-bold" style="margin-right: 8px">下单时间</span>
                              <span>{{ formatDate(purchase.preparedDatetime) }}</span>
                            </li>

                            <li class="w-[80px] mr-[50px]">
                              <a-badge v-if="purchase.status.code === 'P'" status="error" text="待接单" />
                              <a-badge v-if="purchase.status.code === 'C'" status="warning" text="已接单" />
                              <a-badge v-if="purchase.status.code === 'R'" status="default" text="已取消" />
                            </li>
                            <li class="w-[80px] operation-block">
                              <a-button type="link" @click="table.detail.click(purchase)">查看</a-button>
                            </li>
                          </ul>
                        </div>
                      </template>
                      <ul style="border-top: 1px solid #0d8d8d">
                        <li
                          class="w-full px-[24px] py-[16px] border"
                          style="border-top: none"
                          v-for="goods in purchase.showPurchaseGoods"
                          :key="goods.id"
                        >
                          <ul class="flex items-center">
                            <li class="w-[130px] mr-[24px] truncate font-bold">
                              {{ goods.quotationGoods.inquiryGoods.name }}
                            </li>
                            <li class="w-[120px] mr-[24px]">
                              <span>{{ goods.quotationGoods.oem }}</span>
                            </li>
                            <li class="w-[100px] font-bold text-[16px] mr-[24px]">
                              {{ `￥${formatNumber(goods.price, 2)}*${goods.count}` }}
                            </li>
                            <li class="w-[30px] mr-[24px]">
                              {{ goods.quotationGoods.qualityType.message }}
                            </li>
                            <li class="w-[60px] mr-[24px]">
                              {{
                                !goods.quotationGoods.reserveDays ? '现货' : `订货${goods.quotationGoods.reserveDays}天`
                              }}
                            </li>
                            <li class="w-[150px] mr-[24px]">
                              <span>小计：</span>
                              <span class="font-bold text-16px">{{ formatMoney(goods.amount, '￥') }}</span>
                            </li>
                            <li class="w-[60px]" v-if="!table.loading && tabs.purchaseStatus === 'C'">
                              <a-badge
                                :status="
                                  goods.status.code === 'A'
                                    ? 'success'
                                    : goods.status.code === 'C'
                                    ? 'warning'
                                    : 'error'
                                "
                                :text="goods.status.message"
                              />
                              <!-- <a-badge v-if="goods.status.code === 'C'" status="warning" text="已接单" />
                            <a-badge v-if="goods.status.code === 'R'" status="default" text="已取消" /> -->
                            </li>
                          </ul>
                        </li>
                        <div v-if="purchase.more" class="more-block">
                          <a-button type="link" @click="table.morePurchaseGoods.click(purchase)"
                            >显示更多配件
                            <DownOutlined style="color: #269995" />
                          </a-button>
                        </div>
                      </ul>
                    </e-collapse-item>
                  </div>
                  <div v-if="inquiry.more" class="more-block">
                    <a-button type="link" @click="table.morePurchase.click(inquiry)"
                      >显示更多订单
                      <DownOutlined style="color: #269995" />
                    </a-button>
                  </div>
                </section>
              </template>
              <template v-else>
                <a-empty></a-empty>
              </template>
            </div>
          </li>
        </ul>
        <div class="flex justify-end" style="margin-top: 24px">
          <a-pagination
            v-model:current="table.paging.pageIndex"
            v-model:pageSize="table.paging.pageSize"
            :total="table.paging.itemCount"
            @change="table.changePage"
          />
        </div>
      </a-spin>
    </div>
  </view-wrapper>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { useTable, useAjax, formatMoney, formatNumber, formatDate, useWebim } from '@vue-mfe/utils'
import { useRouter, useRoute } from 'vue-router'

import {
  Tabs as ATabs,
  TabPane as ATabPane,
  Row as ARow,
  Col as ACol,
  Tooltip as ATooltip,
  Pagination as APagination,
  Badge as ABadge,
  Empty as AEmpty,
  Spin as ASpin
} from 'ant-design-vue'
import { DownOutlined, PhoneFilled, MessageFilled } from '@ant-design/icons-vue'
import ECollapseItem from '@client/components/e-collapse-item.vue'

import { cloneDeep } from 'lodash-es'
import moment from 'moment'

import type { ColumnProps } from 'ant-design-vue/es/table/interface'

const webim = useWebim()
const router = useRouter()
const route = useRoute()
const supplierId = route.query.supplierId
const { data: statusData } = useAjax(
  {
    action: 'GET /common/lookup/IQPCSTAT'
  }
)
const tabs = reactive(
  {
    purchaseStatus: 'P',
    change () {
      table.paging = {
        pageIndex: 1,
        pageSize: 5
      }
      table.get()
    }
  }
)
const form = reactive(
  {
    data: {
      vin: '',
      quickSearch: '',
      purchaseSerialNo: '',
      inquiryGoodsName: '',
      purchaseStartDate: '',
      purchaseEndDate: ''
    },
    init () {
      form.data.vin = ''
      form.data.quickSearch = ''
      form.data.purchaseSerialNo = ''
      form.data.inquiryGoodsName = ''
      form.data.purchaseStartDate = ''
      form.data.purchaseEndDate = ''
    },
    search () {
      table.get()
    }
  }
)
if (supplierId) {
  form.data.purchaseStartDate = moment().startOf('month').format('YYYY-MM-DD')
  form.data.purchaseEndDate = moment().format('YYYY-MM-DD')
}
const table = useTable(
  {
    ajax: {
      get: {
        action: 'GET /client/inquiry/purchase',
        params: () => ({
          purchaseStatus: tabs.purchaseStatus,
          ...form.data,
          supplierId
        }),
        convert: {
          client (data: any) {
            data.forEach(
              (inquiry: any) => {
                inquiry.showPurchases = [] as any[]
                inquiry.purchases.forEach(
                  (purchase: any) => {
                    purchase.showPurchaseGoods = []
                    if (purchase.purchaseGoods.length <= 3) {
                      purchase.more = false
                      purchase.showPurchaseGoods = cloneDeep(purchase.purchaseGoods)
                    } else {
                      purchase.more = true
                      for (let i = 0; i < 3; i++) {
                        purchase.showPurchaseGoods.push(cloneDeep(purchase.purchaseGoods[i]))
                      }
                    }
                  }
                )
                if (inquiry.purchases.length <= 3) {
                  inquiry.more = false
                  inquiry.showPurchases = cloneDeep(inquiry.purchases)
                } else {
                  inquiry.more = true
                  for (let i = 0; i < 3; i++) {
                    inquiry.showPurchases.push(cloneDeep(inquiry.purchases[i]))
                  }
                }
              }
            )
            return data
          }
        }
      }
    },
    paging: {
      pageIndex: 1,
      pageSize: 5
    },
    columns: [
      {
        title: '订单号',
        dataIndex: 'serial',
        width: 200,
        slots: { customRender: 'serial' }
      },
      {
        title: '车型',
        dataIndex: 'vehicle',
        slots: { customRender: 'vehicle' }
      },
      {
        title: '下单时间',
        dataIndex: 'inqueryTime',
        width: 200,
        align: 'right',
        slots: { customRender: 'inqueryTime' }
      },
      {
        title: '采购商',
        width: 200,
        align: 'right',
        dataIndex: 'vehicle',
        slots: { customRender: 'purchaser' }
      },
      {
        title: '采购人',
        dataIndex: 'inquireBy',
        width: 200,
        align: 'right',
        slots: { customRender: 'inquireBy' }
      },
      {
        title: '总计',
        width: 200,
        align: 'right',
        dataIndex: 'amount',
        slots: { customRender: 'amount' }
      },
      {
        title: '操作',
        width: 50,
        align: 'right',
        slots: { customRender: 'operation' }
      }
    ] as ColumnProps[],
    changePage (page: number) {
      table.paging.pageIndex = page
      table.get()
    },
    detail: {
      click (row: any) {
        router.push({ path: `/client/purchase/order/${row.id}` })
      }
    },
    morePurchaseGoods: {
      click (purchase: any) {
        const limit = 3
        const { showPurchaseGoods, purchaseGoods } = purchase
        if (showPurchaseGoods.length + limit >= purchaseGoods.length) {
          purchase.more = false
          purchase.showPurchaseGoods = cloneDeep(purchaseGoods)
        } else {
          const initLength = showPurchaseGoods.length
          for (let i = initLength; i < initLength + limit; i++) {
            purchase.showPurchaseGoods.push(purchaseGoods[i])
          }
        }
      }
    },
    morePurchase: {
      click (inquiry: any) {
        const limit = 5
        const { showPurchases, purchases } = inquiry
        if (showPurchases.length + limit >= purchases.length) {
          inquiry.more = false
          inquiry.showPurchases = cloneDeep(purchases)
        } else {
          const initLength = showPurchases.length
          for (let i = initLength; i < initLength + limit; i++) {
            inquiry.showPurchases.push(purchases[i])
          }
        }
      }
    },
    row: {
      className (index: any) {
        return Boolean(index % 2)
      }
    }
  }
)
</script>

<style scoped>
.list-item-content .collapse-item:last-child {
  margin-bottom: 0;
}
.operation-box .operation-block {
  visibility: hidden;
}
.operation-box:hover .operation-block {
  visibility: visible;
}
</style>
